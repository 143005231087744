import React, { useState, useEffect, useCallback, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  collection,
  doc, 
  setDoc, 
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  onSnapshot, 
  serverTimestamp,
  arrayUnion,
  query,
  orderBy,
  limit,
  where
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signOut } from 'firebase/auth';
import { PlusCircle, Send, Menu, Mic, StopCircle, Volume2, VolumeX, MoreVertical, Edit, Trash2, LogOut } from 'lucide-react';
import { defaultMetadata } from './metadata.js';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "gclouds-gemini.firebaseapp.com",
  projectId: "gclouds-gemini",
  storageBucket: "gclouds-gemini.appspot.com",
  messagingSenderId: "1044743037959",
  appId: "1:1044743037959:web:36e77a5e9227e2322c36a2",
  measurementId: "G-3K9HKY061R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

function SessionOptionsMenu({ onRename, onDelete }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="session-options">
      <button onClick={() => setIsOpen(!isOpen)} className="options-toggle">⋮</button>
      {isOpen && (
        <div className="options-menu">
          <button onClick={() => { onRename(); setIsOpen(false); }} className="option-button">Rename</button>
          <button onClick={() => { onDelete(); setIsOpen(false); }} className="option-button">Delete</button>
        </div>
      )}
    </div>
  );
}

function Chat({ user }) {
  const [prompt, setPrompt] = useState('');
  const [displayedConversation, setDisplayedConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [topic] = useState('General');
  const [metadata, setMetadata] = useState(localStorage.getItem('chatMetadata') || defaultMetadata);
  const [sessionId, setSessionId] = useState(null);
  const [sessionName, setSessionName] = useState('');
  const [error, setError] = useState(null);
  const [sessions, setSessions] = useState({
    today: [],
    yesterday: [],
    previous7Days: [],
    previous30Days: [],
    older: []
  });
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [isMuted, setIsMuted] = useState({});
  const [sessionCounter, setSessionCounter] = useState(1);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const [editingSessionName, setEditingSessionName] = useState('');
  const [openOptionsMenu, setOpenOptionsMenu] = useState(null);
  const audioRef = useRef(null);
  const chatContainerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const initializationInProgress = useRef(false);
  const [displayedMessageIds, setDisplayedMessageIds] = useState(new Set());
  const [showAudioPrompt, setShowAudioPrompt] = useState(false);
  const [isInitializingSession, setIsInitializingSession] = useState(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const auth = getAuth();
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // Redirect to login page or update app state
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const fetchSessions = useCallback(async () => {
    try {
      const db = getFirestore();
      const sessionsRef = collection(db, 'conversations');
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
      const q = query(
        sessionsRef, 
        where("userId", "==", user.uid),
        orderBy('lastUpdated', 'desc')
      );
  
      const querySnapshot = await getDocs(q);
      const fetchedSessions = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name || 'Untitled Session',
        lastUpdated: doc.data().lastUpdated?.toDate() || new Date()
      }));
  
      const groupedSessions = {
        today: [],
        yesterday: [],
        previous7Days: [],
        previous30Days: [],
        older: []
      };
  
      fetchedSessions.forEach(session => {
        if (session.lastUpdated >= today) {
          groupedSessions.today.push(session);
        } else if (session.lastUpdated >= yesterday) {
          groupedSessions.yesterday.push(session);
        } else if (session.lastUpdated >= sevenDaysAgo) {
          groupedSessions.previous7Days.push(session);
        } else if (session.lastUpdated >= thirtyDaysAgo) {
          groupedSessions.previous30Days.push(session);
        } else {
          groupedSessions.older.push(session);
        }
      });
  
      setSessions(groupedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setError('An error occurred while fetching sessions. Please try again later.');
    }
  }, [user.uid]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  useEffect(() => {
    if (sessionId && user) {
      const db = getFirestore();
      const unsubscribe = onSnapshot(
        doc(db, 'conversations', sessionId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.userId === user.uid) {
              const messages = data.messages || [];
              const visibleMessages = messages.filter(msg => !msg.isHidden);
              setDisplayedConversation(visibleMessages);
              setSessionName(data.name || 'Untitled Session');
            } else {
              setError("You don't have permission to access this conversation.");
            }
          } else {
            setError("The requested conversation does not exist.");
          }
        },
        (error) => {
          console.error("Error in Firestore snapshot listener:", error);
          setError('Error listening to conversation updates: ' + error.message);
        }
      );
      return () => unsubscribe();
    }
  }, [sessionId, user]);

  const loadSessionCounter = useCallback(async () => {
    const counterDoc = await getDoc(doc(db, 'metadata', 'sessionCounter'));
    if (counterDoc.exists()) {
      setSessionCounter(counterDoc.data().count);
    } else {
      await setDoc(doc(db, 'metadata', 'sessionCounter'), { count: 1 });
    }
  }, []);

  const streamAudio = useCallback((audioUrl) => {
    if (audioRef.current) {
      audioRef.current.src = audioUrl;
      audioRef.current.muted = isMuted;
      audioRef.current.play().catch(e => console.error("Error playing audio:", e));
    }
  }, [isMuted]);

  useEffect(() => {
    if (displayedConversation.length > 0) {
      const lastMessage = displayedConversation[displayedConversation.length - 1];
      if (lastMessage.role === 'model' && lastMessage.audioUrl) {
        streamAudio(lastMessage.audioUrl);
      }
    }
  }, [displayedConversation, streamAudio]);

  const initializeConversation = async (newSessionId) => {
    setIsLoading(true);
    try {
      const metadataToUse = localStorage.getItem('chatMetadata') || defaultMetadata;
  
      const callGemini = httpsCallable(functions, 'callGemini');
      const result = await callGemini({ 
        prompt: metadataToUse,
        topic: topic,
        sessionId: newSessionId,
        isInitializing: true
      });
  
      if (result.data.error) {
        throw new Error(result.data.error);
      }
  
      const newCounter = sessionCounter + 1;
      setSessionCounter(newCounter);
      await setDoc(doc(db, 'metadata', 'sessionCounter'), { count: newCounter });
  
      setSessionId(newSessionId);
      localStorage.setItem('sessionId', newSessionId);
  
      setDisplayedConversation([{ 
        role: 'model', 
        parts: result.data.response, 
        audioUrl: result.data.audioUrl 
      }]);
  
      const newSessionName = `Untitled chat-${newCounter.toString().padStart(2, '0')}`;
      setSessionName(newSessionName);
  
      await updateDoc(doc(db, 'conversations', newSessionId), {
        name: newSessionName
      });
  
      setAudioUrl(result.data.audioUrl);
  
      await fetchSessions();
  
    } catch (error) {
      console.error('Error initializing conversation:', error);
      setError('Error initializing conversation: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initSession = async () => {
      if (isInitialized) return;
      
      await loadSessionCounter();
      await fetchSessions();
      setIsInitialized(true);
    };

    initSession();
  }, [loadSessionCounter, fetchSessions, isInitialized]);


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [displayedConversation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (prompt.trim() === '' || !sessionId) return;
    
    setIsLoading(true);
    setError(null);

    try {
      const callGemini = httpsCallable(functions, 'callGemini');
      const result = await callGemini({ 
        prompt, 
        topic,
        sessionId,
        isInitializing: false
      });

      if (result.data.error) {
        throw new Error(result.data.error);
      }

      // Clear the prompt after successful submission
      setPrompt('');

      // The Firestore listener will update the displayed conversation
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setError('Error processing request: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSessionClick = useCallback(async (clickedSessionId) => {
    if (clickedSessionId === sessionId) return;

    setSessionId(clickedSessionId);
    localStorage.setItem('sessionId', clickedSessionId);
    
    const docRef = doc(db, 'conversations', clickedSessionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.userId === user.uid) {
        setDisplayedConversation(data.messages.filter(msg => !msg.isHidden));
        setSessionName(data.name || 'Untitled Session');
      } else {
        setError("You don't have permission to access this conversation.");
        setDisplayedConversation([]);
        setSessionName('');
      }
    }
  }, [sessionId, user.uid]);

  const handleNewSession = async () => {
    const newSessionId = `session_${Date.now()}`;
    setIsLoading(true);
    setError(null);
    setShowAudioPrompt(true);
    setIsInitializingSession(true);
    setSessionId(newSessionId); // Immediately set the session ID
    
    try {
      // Create the initial document structure
      const docRef = doc(db, 'conversations', newSessionId);
      await setDoc(docRef, {
        topic: topic,
        messages: [{ role: 'user', parts: metadata, isHidden: true }],
        lastUpdated: serverTimestamp(),
        name: `Untitled chat-${sessionCounter.toString().padStart(2, '0')}`,
        userId: user.uid
      });
  
      const callGemini = httpsCallable(functions, 'callGemini');
      const result = await callGemini({ 
        sessionId: newSessionId,
        isInitializing: true
      });
  
      if (result.data.error) {
        throw new Error(result.data.error);
      }
  
      localStorage.setItem('sessionId', newSessionId);
      setSessionName(`Untitled chat-${sessionCounter.toString().padStart(2, '0')}`);
      setSessionCounter(prev => prev + 1);
      setAudioUrl(result.data.audioUrl);
      await fetchSessions();

    } catch (error) {
      console.error('Error in handleNewSession:', error);
      setError('Error initializing new session: ' + error.message);
    } finally {
      setIsLoading(false);
      setIsInitializingSession(false);
    }
  };

    useEffect(() => {
      if (displayedConversation.length > 0) {
        setShowAudioPrompt(false);  // Hide the audio prompt once we receive a response
      }
    }, [displayedConversation]);

  const handleDeleteSession = useCallback(async (sessionIdToDelete) => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      try {
        await deleteDoc(doc(db, 'conversations', sessionIdToDelete));
        if (sessionIdToDelete === sessionId) {
          setSessionId(null);
          setSessionName('');
          setDisplayedConversation([]);
          localStorage.removeItem('sessionId');
        }
        await fetchSessions();
      } catch (error) {
        console.error('Error deleting session:', error);
        setError('Error deleting session: ' + error.message);
      }
    }
  }, [sessionId, fetchSessions]);

  const handleRenameSession = useCallback(async (sessionId, newName) => {
    if (newName && newName.trim() !== '') {
      await updateDoc(doc(db, 'conversations', sessionId), {
        name: newName.trim(),
        lastUpdated: serverTimestamp()
      });
      if (sessionId === sessionId) {
        setSessionName(newName.trim());
      }
      fetchSessions();
      setEditingSessionId(null);
    }
  }, [sessionId, fetchSessions]);

  const toggleMute = useCallback(() => {
    setIsMuted(prevMuted => {
      const newMuted = !prevMuted;
      if (audioRef.current) {
        audioRef.current.muted = newMuted;
      }
      return newMuted;
    });
  }, []);

  const renderSessionGroup = (title, sessions) => {
    if (sessions.length === 0) return null;
    return (
      <div key={title}>
        <h3>{title}</h3>
        {sessions.map((session) => (
          <div 
            key={session.id} 
            className={`session-item ${session.id === sessionId ? 'active' : ''}`}
          >
            {editingSessionId === session.id ? (
              <input
                className="inline-edit"
                value={editingSessionName}
                onChange={(e) => setEditingSessionName(e.target.value)}
                onBlur={() => handleRenameSession(session.id, editingSessionName)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleRenameSession(session.id, editingSessionName);
                  }
                }}
                autoFocus
              />
            ) : (
              <div 
                className="session-name"
                onClick={() => handleSessionClick(session.id)}
              >
                {session.name}
              </div>
            )}
            <SessionOptionsMenu 
              onRename={() => {
                setEditingSessionId(session.id);
                setEditingSessionName(session.name);
              }}
              onDelete={() => handleDeleteSession(session.id)}
            />
          </div>
        ))}
        <hr />
      </div>
    );
  };

  const initialTips = [
//    { icon: "👋", text: "Welcome to the Pod!", type: "welcome" },
    { icon: "✨", text: "Quick Tips", type: "subheader" },
    { icon: "💡", text: "Keep it conversational", type: "tip" },
    { icon: "🤝", text: "Be honest and open", type: "tip" },
    { icon: "🎭", text: "This isn't a job interview—just a friendly chat!", type: "tip" },
  ];

  const renderLogo = () => (
    <div className="logo-container">
      <svg viewBox="0 0 100 100" width="50" height="50">
        <path d="M50 0 L100 25 L100 75 L50 100 L0 75 L0 25 Z" fill="none" stroke="currentColor" strokeWidth="5" />
        <path d="M50 25 L75 37.5 L75 62.5 L50 75 L25 62.5 L25 37.5 Z" fill="none" stroke="currentColor" strokeWidth="5" />
      </svg>
    </div>
  );

  const renderSuggestions = () => (
    <div className="suggestions-container">
      {renderLogo()}
      <div className="suggestions-grid">
        {initialTips.map((tip, index) => (
          <div key={index} className="suggestion-item">
            <span className="suggestion-icon">{tip.icon}</span>
            <p>{tip.text}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const renderInitialState = () => (
    <div className="initial-state">
      <div className="initial-tips">
        <h3>Getting Started</h3>
        {initialTips.map((tip, index) => (
          <p key={index} className={
            tip.startsWith('•') ? 'tip' :
            tip.startsWith('Quick Tips:') ? 'heading' : ''
          }>
            {tip}
          </p>
        ))}
      </div>
      <button onClick={handleNewSession} className="new-chat-button">
        <PlusCircle size={18} style={{ marginRight: '5px' }} />
        New chat
      </button>
    </div>
  );

  const renderInitialTips = () => (
    <div className="initial-tips">
      <h3>Getting Started</h3>
      {initialTips.map((tip, index) => (
        <p key={index} className={
          tip.startsWith('•') ? 'tip' :
          tip.startsWith('Quick Tips:') ? 'heading' : ''
        }>
          {tip}
        </p>
      ))}
    </div>
  );

  return (
    <div className="chat-container">
      <button className="menu-button" onClick={toggleSidebar}>
        <Menu size={24} />
      </button>
      
      <div className={`sidebar ${isSidebarOpen ? 'sidebar-visible' : 'sidebar-hidden'}`}>
        <div className="sidebar-content">
          <h2>Sessions</h2>
          <button onClick={handleNewSession} className="new-chat-button">
            <PlusCircle size={18} style={{ marginRight: '5px' }} />
            New chat
          </button>
          {renderSessionGroup('Today', sessions.today)}
          {renderSessionGroup('Yesterday', sessions.yesterday)}
          {renderSessionGroup('Previous 7 Days', sessions.previous7Days)}
          {renderSessionGroup('Previous 30 Days', sessions.previous30Days)}
          {renderSessionGroup('Older', sessions.older)}
        </div>
        <div className="sidebar-footer">
          <div className="user-info">
            <span>{user.email}</span>
          </div>
          <button onClick={handleSignOut} className="logout-button">
            <LogOut size={18} style={{ marginRight: '5px' }} />
            Log Out
          </button>
        </div>
      </div>
  
      <div className="main-content">
        <div className="main-header">
          <button className="menu-button" onClick={toggleSidebar}>
            <Menu size={24} />
          </button>
          <h1>Pod</h1>
          <button onClick={toggleMute} className="mute-button">
            {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
          </button>
        </div>
        
        {error && <div className="error-message">{error}</div>}

        <div className="chat-messages" ref={chatContainerRef}>
          {!sessionId ? (
            <>
              {renderSuggestions()}
            </>
          ) : (
            <>
            {showAudioPrompt && (
              <div className="audio-prompt">
                Please put on your headphones or ensure your device's sound is on. 
                Nia will start speaking to you in a moment.
              </div>
            )}
            {displayedConversation.map((message, index) => (
              <div 
                key={index} 
                className={`message-container ${message.role === 'user' ? 'user-message' : 'model-message'}`}
              >
                <div className="message">
                  <strong>{message.role === 'user' ? 'You: ' : 'Nia: '}</strong>
                  {message.parts}
                </div>
              </div>
              ))}
              </>
            )}
          </div>

        <audio ref={audioRef} style={{ display: 'none' }} />
        
        <div className="chat-input-container">
          {!sessionId ? (
            <button onClick={handleNewSession} className="new-chat-button">
              <PlusCircle size={18} style={{ marginRight: '5px' }} />
              New chat
            </button>
          ) : (
            <form onSubmit={handleSubmit} className="chat-form">
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Send a message"
                className="prompt-area"
              />
              <button 
                type="submit" 
                disabled={isLoading}
                className="send-button"
              >
                <Send size={18} />
              </button>
            </form>
          )}
          <p className="disclaimer">Pod can make mistakes.</p>
        </div>
      </div>
    </div>
  );
}

export default Chat;