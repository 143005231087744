import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "gclouds-gemini.firebaseapp.com",
  projectId: "gclouds-gemini",
  storageBucket: "gclouds-gemini.appspot.com",
  messagingSenderId: "1044743037959",
  appId: "1:1044743037959:web:36e77a5e9227e2322c36a2",
  measurementId: "G-3K9HKY061R"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { app, auth, db, storage, functions };